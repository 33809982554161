function groupBy(arr, key) {
  return arr.reduce((acc, obj) => {
    let value = obj[key];
    if (!acc[value]) {
      acc[value] = [];
    }
    acc[value].push(obj);
    return acc;
  }, {});
}
export default groupBy;
