import { configureStore, combineReducers } from "@reduxjs/toolkit";
import api from "./middlewares/api";
import users from "./users";
import departments from "./departments";
import employees from "./employees";
import daysoff from "./daysoff";

const reducers = combineReducers({
  users,
  departments,
  employees,
  daysoff,
});
const rootReducer = (state, action) => {
  if (action.type === "users/logoutSuccess") {
    localStorage.clear();
    state = undefined;
  }

  return reducers(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["apiCall"],
      },
    }),
    api,
  ],
});
