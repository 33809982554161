import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "employees",
  initialState: {
    loading: false,
    fetched: false,
    total: 0,
    list: [],

    error: null,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
      state.error = null;
    },
    resetData: (state, action) => {
      state.loading = true;
      state.list = [];
    },
    getSuccess: (state, action) => {
      const { data } = action.payload;
      state.list = data;
    },
    getError: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    createSuccess: (state, action) => {
      state.list = state.list.map((item) => {
        for (let i = 0; i < action.payload.length; i++) {
          if (action.payload[i].userId === item.userId) {
            return action.payload[i];
          }
        }
        return item;
      });
    },
    createError: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    deleteSuccess: (state, action) => {
      window.location.reload();
    },
    deleteError: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});
export default slice.reducer;

export const { setLoading, resetData } = slice.actions;

export const getEmployees =
  ({ query }) =>
  (dispatch, getState) => {
    dispatch(resetData());
    dispatch({
      type: "apiCall",
      payload: {
        url: `/employees${query}`,
        method: "get",
        onSuccess: "employees/getSuccess",
        onError: "employees/getError",
      },
    });
  };

export const createEmployee = (data, response) => (dispatch, getState) => {
  dispatch({
    type: "apiCall",
    payload: {
      url: "/employees",
      method: "post",
      data,
      onSuccess: "employees/createSuccess",
      onError: "employees/createError",
    },
  })
    .then((e) => response(e))
    .catch((e) => response(e));
};
export const deleteEmployee = (data, response) => (dispatch, getState) => {
  dispatch({
    type: "apiCall",
    payload: {
      url: "/employees",
      method: "delete",
      data,
      onSuccess: "employees/deleteSuccess",
      onError: "employees/deleteError",
    },
  })
    .then((e) => response(e))
    .catch((e) => response(e));
};