import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/default";
import "./theme/style.css";
import store from "./store";
import { Provider } from "react-redux";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ArrowBack } from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <CacheProvider value={cacheRtl}>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
      <ToastContainer
        position="bottom-left"
        hideProgressBar
        rtl
        theme="colored"
        closeButton={() => <ArrowBack />}
      />
    </ThemeProvider>
  </CacheProvider>
);
