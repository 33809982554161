import moment from "moment";
import calculateOverTime from "./calculateOverTime";

const calculateWorkHours = (timeIn, timeOut) => {
  if (timeIn === null || timeOut === null) {
    return { hours: null, overtime: null };
  }
  var startTime = moment(timeIn, "HH:mm");
  var endTime = moment(timeOut, "HH:mm");

  var duration = moment.duration(endTime.diff(startTime));
  var hours = parseInt(duration.asHours());
  var minutes = parseInt(duration.asMinutes()) % 60;

  return {
    hours: hours + ":" + minutes,
    overtime: calculateOverTime({ hours, minutes }),
  };
};

export default calculateWorkHours;
