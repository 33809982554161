import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutSuccess } from "../store/users";

import AuthContext from "./AuthContext";

function useAuth() {
  return React.useContext(AuthContext);
}

function AuthProvider({ children }) {
  const { currentUser } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(logoutSuccess());
  };
  return (
    <AuthContext.Provider value={{ currentUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;

export { useAuth };
