import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "daysoff",
  initialState: {
    loading: false,
    fetched: false,
    total: 0,
    list: [],
    error: null,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
      state.error = null;
    },
    getSuccess: (state, action) => {
      const { data, total } = action.payload;
      state.total = total;
      state.list = data;
      state.loading = false;
      state.fetched = true;
    },
    getError: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    createSuccess: (state, action) => {
      state.loading = false;
      state.list = [...state.list, ...action.payload];
      state.total += 1;
    },
    createError: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    deleteSuccess: (state, action) => {
      const { _id } = action.payload;
      state.list = state.list.filter((item) => _id !== item._id);
    },
  },
});
export default slice.reducer;

export const { setLoading } = slice.actions;

export const getDaysoff =
  ({ page }, response) =>
  (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch({
      type: "apiCall",
      payload: {
        url: `/daysoff?page=${page}`,
        method: "get",
        onSuccess: "daysoff/getSuccess",
        onError: "daysoff/getError",
      },
    })
      .then((e) => response(e))
      .catch((e) => response(e));
  };

export const createDayoff = (data, response) => (dispatch, getState) => {
  dispatch({
    type: "apiCall",
    payload: {
      url: "/daysoff",
      method: "post",
      data,
      onSuccess: "daysoff/createSuccess",
      onError: "daysoff/createError",
    },
  })
    .then((e) => response(e))
    .catch((e) => response(e));
};
export const deleteDayoff = (data, response) => (dispatch, getState) => {
  dispatch({
    type: "apiCall",
    payload: {
      url: "/daysoff",
      method: "delete",
      data,
      onSuccess: "daysoff/deleteSuccess",
      onError: "daysoff/deleteError",
    },
  })
    .then((e) => response(e))
    .catch((e) => response(e));
};
