import axios from "axios";
import { toast } from "react-toastify";
import { baseURL } from "../../config";
import { logoutSuccess, logoutUser } from "../users";

const api =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    if (action.type !== "apiCall") {
      return next(action);
    }
    next(action);
    const { url, method, data, onSuccess, onError } = action.payload;

    try {
      const response = await axios.request({
        baseURL,
        headers: {
          token: getState().users?.currentUser?.token,
          "content-type": "application/json",
        },
        url,
        method,
        data,
      });

      console.log("api result : ", response.data);
      if (response.data.error?.action === "logout") {
        dispatch(logoutSuccess());
      }
      if (response.data.error) {
        toast.error(response.data.error.msg);
        if (onError) {
          dispatch({
            type: onError,
            payload: { ...response.data, postedData: data },
          });
        }

        return { error: true };
      }
      if (onSuccess) {
        dispatch({ type: onSuccess, payload: response.data });
        return { success: true };
      }
    } catch (error) {
      toast.error(error.message);
      if (onError) {
        dispatch({
          type: onError,
          payload: { error: { msg: error.message, ...data } },
        });
      }

      return { error: true };
    }
  };
export default api;
