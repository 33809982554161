import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "departments",
  initialState: {
    loading: false,
    fetched: false,
    total: 0,
    list: [],
    error: null,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
      state.error = null;
    },
    getSuccess: (state, action) => {
      const { data, total } = action.payload;
      state.total = total;
      state.list = data;
      state.loading = false;
      state.fetched = true;
    },
    getError: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    createSuccess: (state, action) => {
      state.loading = false;
      state.list.unshift(action.payload);
      state.total += 1;
    },
    createError: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});
export default slice.reducer;

export const { setLoading } = slice.actions;

export const getDepartments = () => (dispatch, getState) => {
  dispatch(setLoading(true));
  dispatch({
    type: "apiCall",
    payload: {
      url: `/departments`,
      method: "get",
      onSuccess: "departments/getSuccess",
      onError: "departments/getError",
    },
  });
};

export const createDepartment = (data, response) => (dispatch, getState) => {
  dispatch({
    type: "apiCall",
    payload: {
      url: "/departments",
      method: "post",
      data,
      onSuccess: "departments/createSuccess",
      onError: "departments/createError",
    },
  })
    .then((e) => response(e))
    .catch((e) => response(e));
};
