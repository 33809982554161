import React, { useState, useEffect } from "react";
import { CircularProgress, Autocomplete, TextField } from "@mui/material";
import fetch from "../utils/fetch";
export default function SearchDepartment({ onChange }) {
  const [searchResults, setSearchResult] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [query, setQuery] = useState("");
  useEffect(() => {
    onSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  const onSearch = async () => {
    if (query.length > 0) {
      setLoading(true);
      const { data } = await fetch({
        url: `/departments?search=${query}`,
        method: "get",
      });
      setLoading(false);
      setSearchResult(data);
    }
  };
  return (
    <Autocomplete
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option._id === value._id}
      onChange={(e, value) => onChange(value)}
      getOptionLabel={(option) => option.name}
      options={searchResults}
      loading={open && loading.search}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search Department..."
          variant="outlined"
          margin="dense"
          onChange={(e) => setQuery(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading.search ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
