import * as React from "react";
import Modal from "../../components/Modal";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { Box, Button, Divider, Stack, TextField } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createDepartment } from "../../store/departments";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import csvToJson from "../../utils/csvToJson";

import { colId, colName } from "../../utils/constants";
import { createEmployee } from "../../store/employees";
export default function CreateEmployee() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const department = searchParams.get("department");
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  const onCreate = () => {
    setLoading(true);
    if (data.length === 1 && !data[0].department) {
      data[0].department = department;
    }
    dispatch(
      createEmployee(data, (e) => {
        if (e.error) {
          setLoading(false);
        }
        if (e.success) {
          toast.success("Data has been Successfully created");
          navigate(-1);
        }
      })
    );
  };

  const onFileChange = (e) => {
    const acceptedFiles = e.target.files[0];

    if (acceptedFiles) {
      const reader = new FileReader();
      reader.onabort = () => {};
      reader.onerror = () => {};
      reader.onloadend = () => {};
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        let data = XLSX.utils.sheet_to_csv(ws, { header: 1 });

        data = csvToJson(data);

        let uniqueIds = {};
        let finalData = [];

        for (const item of data) {
          if (!uniqueIds[item[colId]] && parseInt(item[colId])) {
            finalData.push({
              userId: item[colId],
              name: item[colName],
              department: department,
            });
            uniqueIds[item[colId]] = item[colId];
          }
        }
        console.log(finalData);
        setData(finalData);
      };
      reader.readAsBinaryString(acceptedFiles);
    }
  };
  return (
    <Modal
      title="موظف جديد"
      buttons={[
        { title: "الغاء" },
        { title: "انشاء", loading, onClick: onCreate },
      ]}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Button
          startIcon={<FilePresentIcon />}
          variant="contained"
          component="label"
        >
          اضافة من ملف اكسل
          <input
            hidden
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            type="file"
            onChange={onFileChange}
          />
        </Button>
        <table className="table">
          <thead>
            <tr>
              <td colSpan={2}>يجب ان يحتوي الملف على الحقول التالية</td>
            </tr>
          </thead>
          <tr>
            <th>{colId}</th>
            <th>{colName}</th>
          </tr>
        </table>
        {data.length > 1 && (
          <Button variant="outlined" onClick={() => setData([])}>
            Reset
          </Button>
        )}
      </Stack>
      {(data.length > 1 && (
        <table className="table">
          <thead>
            <tr>
              <td>id</td>
              <td>name</td>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.userId}>
                <td>{item.userId}</td>
                <td>{item.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )) || (
        <Box>
          <TextField
            autoComplete="off"
            label={colId}
            fullWidth
            type="number"
            margin="normal"
            variant="outlined"
            value={data[0]?.userId}
            onChange={(e) =>
              setData((prev) => {
                return [{ ...(prev[0] || {}), userId: e.target.value }];
              })
            }
          />
          <TextField
            label={colName}
            autoComplete="off"
            fullWidth
            value={data[0]?.name}
            margin="normal"
            variant="outlined"
            onChange={(e) =>
              setData((prev) => {
                return [{ ...(prev[0] || {}), name: e.target.value }];
              })
            }
          />
        </Box>
      )}
    </Modal>
  );
}
