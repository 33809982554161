const colId = "AC-No";
const colDate = "Date";
const colDepartment = "Department";
const colName = "Name";
const colTime = "Time";
const colTimeIn = "TimeIn";
const colTimeOut = "TimeOut";
const colWorkHours = "workHours";
const colRecordsCount = "recordsCount";
const colOvertime = "overtime";
const colDayoff = "dayoff";

export {
  colId,
  colDate,
  colDepartment,
  colName,
  colTime,
  colTimeIn,
  colTimeOut,
  colWorkHours,
  colRecordsCount,
  colDayoff,
  colOvertime,
};
