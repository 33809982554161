import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import GroupIcon from "@mui/icons-material/Group";
import WidgetsIcon from "@mui/icons-material/Widgets";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
const pages = [
  { title: "Employees", icon: <GroupIcon />, route: "/employees" },
  { title: "Departments", icon: <WidgetsIcon />, route: "/departments" },
  { title: "Days Off", icon: <SelfImprovementIcon />, route: "/daysoff" },
];

const ToolsBar = () => {
  return (
    <div className="noprint">
      <AppBar position="fixed" elevation={0} color="transparent">
        <Box bgcolor="background.paper">
          <Container>
            <Toolbar disableGutters>
              <Typography variant="h5">نظام الحظور</Typography>
            </Toolbar>
          </Container>
        </Box>
      </AppBar>
      <Toolbar />
    </div>
  );
};

export default ToolsBar;
