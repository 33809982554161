import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DataReader from "../../components/dataReader";
import validateData from "./utils/validateData";
import groupBy from "./utils/groupBy";
import {
  colDate,
  colId,
  colName,
  colOvertime,
  colRecordsCount,
  colTimeIn,
  colTimeOut,
  colWorkHours,
} from "../../utils/constants";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { Check, Error } from "@mui/icons-material";
import axios from "axios";
import { baseURL } from "../../config";
import DateRange from "../../components/DateRange";
function DataUpload() {
  let [searchParams, setSearchParams] = useSearchParams();

  const fromDate = searchParams.get("fromDate");
  const toDate = searchParams.get("toDate");
  const [loading, setLoading] = useState(false);
  const [saveStack, setSaveStack] = useState({});
  const [stackResult, setStackResult] = useState({});

  const handelReadData = async (data) => {
    setLoading(true);

    try {
      const result = await validateData(data);
      const obj = groupBy(result, colDate);
      const dataKeys = Object.keys(obj);
      let start = moment(fromDate);
      let end = moment(toDate);
      let dateRangeObject = {};
      let i = 0;
      for (
        let m = moment(start);
        m.isBefore(end) || m.isSame(end);
        m.add(1, "days")
      ) {
        let dateKey = m.format("YYYY-MM-DD");
        dateRangeObject[dateKey] = obj[dataKeys[i]]; // Initialize values to null, update as necessary
        i++;
      }
      console.log(dateRangeObject);
      setSaveStack(dateRangeObject);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading({ get: false });
    }
  };

  const onSave = async (data, date) => {
    data = data.map((item) => ({
      userId: item[colId],
      name: item[colName],
      timeIn: item[colTimeIn],
      timeOut: item[colTimeOut],
      recordsCount: item[colRecordsCount],
      workHours: item[colWorkHours],
      overtime: item[colOvertime],
      date: item[colDate],
    }));
    setStackResult((prev) => ({ ...prev, [date]: "loading" }));
    try {
      const { data: result } = await axios.post(`${baseURL}/data`, {
        data,
        date,
      });

      if (result.save === "ok") {
        toast.success("data has been saved");
        setStackResult((prev) => ({ ...prev, [date]: "success" }));
      } else {
        setStackResult((prev) => ({ ...prev, [date]: "error" }));
      }
    } catch (error) {
      console.log(error);
      setStackResult((prev) => ({ ...prev, [date]: "error" }));
    }
  };
  return (
    <Container>
      {fromDate && toDate ? (
        <Box mt={2}>
          <DataReader onRead={handelReadData} />
        </Box>
      ) : (
        <Stack justifyContent="center" alignItems="center" p={5} spacing={2}>
          <Typography>اختر التاريخ</Typography>
          <DateRange
            onSelect={({ endDate, startDate }) => {
              setSearchParams(
                {
                  fromDate: startDate,
                  toDate: endDate,
                },
                { replace: true }
              );
            }}
          />
        </Stack>
      )}
      <Stack gap={1} mt={2}>
        {Object.keys(saveStack).map((date) => (
          <Stack direction="row" key={date}>
            <Typography flexGrow={1}>{date}</Typography>
            <Button
              onClick={() => {
                onSave(saveStack[date], date);
              }}
              startIcon={
                stackResult[date] && stackResult[date] === "loading" ? (
                  <CircularProgress size={15} color="inherit" />
                ) : stackResult[date] === "success" ? (
                  <Check color="inherit" />
                ) : stackResult[date] === "error" ? (
                  <Error color="inherit" />
                ) : null
              }
              endIcon={saveStack[date]?.length || 0}
            >
              publish
            </Button>
          </Stack>
        ))}
      </Stack>
    </Container>
  );
}

export default DataUpload;
