import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FocusLock from "react-focus-lock";
import { RemoveScroll } from "react-remove-scroll";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export default function Modal({ title, buttons, children }) {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };
  React.useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `body::-webkit-scrollbar {display: none;}`;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <RemoveScroll>
      <FocusLock>
        <Dialog
          maxWidth="sm"
          fullWidth
          open
          hideBackdrop
          fullScreen={fullScreen}
          onClose={() => null}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent dividers>{children}</DialogContent>
          {buttons && (
            <DialogActions>
              {buttons?.map((button) => (
                <Button
                  key={button.title}
                  disabled={button.loading}
                  onClick={button.onClick || handleClose}
                  style={{ textTransform: "none" }}
                >
                  <Stack direction="row" spacing={2} alignItems="center">
                    {button.loading && (
                      <CircularProgress style={{ marginLeft: 5 }} size={16} />
                    )}
                    {button.title}
                  </Stack>
                </Button>
              ))}
            </DialogActions>
          )}
        </Dialog>
      </FocusLock>
    </RemoveScroll>
  );
}
