import * as React from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";

import { DateRangePicker } from "materialui-daterange-picker";
import zIndex from "@mui/material/styles/zIndex";
import { DateRangeOutlined } from "@mui/icons-material";

export default function DateRange({ onSelect }) {
  const [date, setDate] = React.useState(Date.now());
  const [value, setValue] = React.useState([null, null]);
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({});
  const toggle = () => setOpen(!open);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <Box>
      <Button onClick={handleClickOpen} startIcon={<DateRangeOutlined />}>
        Date
      </Button>
      <Dialog
        hideBackdrop
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          style={{ direction: "ltr" }}
          display="flex"
          justifyContent="center"
        >
          <DateRangePicker open toggle={toggle} onChange={setDateRange} />
        </Box>
        <DialogActions style={{ zIndex: 100, backgroundColor: "#fff" }}>
          <Button onClick={handleClose}>الغاء</Button>
          <Button
            onClick={() => {
              onSelect({
                startDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
                endDate: moment(dateRange.endDate).format("YYYY-MM-DD"),
              });
              handleClose();
            }}
            autoFocus
          >
            عرض
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
