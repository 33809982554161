import axios from "axios";
import { toast } from "react-toastify";
import { baseURL } from "../config";
import store from "../store";
const fetch = async ({ url, method, data }) => {
  try {
    const response = await axios.request({
      baseURL,
      headers: {
        token: store.getState().users?.currentUser?.token,
        "content-type": "application/json",
      },
      url,
      method,
      data,
    });
    console.log("api result : ", response.data);
    if (response.data.error) {
      return toast.error(response.data.error.msg);
    }
    return response.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export default fetch;
