const calculateOverTime = ({ hours, minutes }) => {
  hours = parseInt(hours);
  minutes = parseInt(minutes);

  if ((hours === 7 && minutes > 0) || hours > 7) {
    let h = 0;

    if (hours > 7) {
      h = hours - 7;
    }

    return `${h} :${minutes}`;
  }
  return "0:0";
};
export default calculateOverTime;
