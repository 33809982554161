import validateTime from "./validateTime";
import {
  colId,
  colDate,
  colDepartment,
  colName,
  colTime,
  colTimeIn,
  colTimeOut,
  colWorkHours,
  colRecordsCount,
  colOvertime,
} from "../../../utils/constants";
import calculateWorkHours from "./calculateWorkHours";
import getTimeInTimeOut from "./getTimeInTimeOut";

const validateData = (data) => {
  return new Promise((resolve, reject) => {
    const result = [];
    data.forEach((record, i) => {
      console.log(i);
      if (
        record.hasOwnProperty(colId) &&
        record.hasOwnProperty(colDate) &&
        record.hasOwnProperty(colDepartment) &&
        record.hasOwnProperty(colName) &&
        record.hasOwnProperty(colTime)
      ) {
        const resultObj = {};
        const timeInOut = getTimeInTimeOut(record[colTime]);
        resultObj[colId] = parseInt(record[colId]);
        resultObj[colDate] = record[colDate];
        resultObj[colDepartment] = record[colDepartment];
        resultObj[colName] = record[colName];
        resultObj[colTimeIn] = timeInOut[colTimeIn];
        resultObj[colTimeOut] = timeInOut[colTimeOut];
        resultObj[colRecordsCount] = timeInOut[colRecordsCount];
        const workHours = calculateWorkHours(
          timeInOut[colTimeIn],
          timeInOut[colTimeOut]
        );
        resultObj[colWorkHours] = workHours.hours;
        resultObj[colOvertime] = workHours.overtime;

        result.push(resultObj);
      } else {
        reject({ error: { msg: `data not valid on col : ${i}` }, record });
      }

      resolve(result);
    });
  });
};

export default validateData;
