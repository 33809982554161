import moment from "moment";
import {
  colRecordsCount,
  colTimeIn,
  colTimeOut,
} from "../../../utils/constants";
const getTimeInTimeOut = (time) => {
  console.log(time);
  let baseTime;
  if (typeof time === "string") {
    baseTime = (time?.length > 0 && time.split(" ")) || [null, null];
  } else if (typeof time === "object") {
    baseTime = time;
  } else {
    return;
  }

  baseTime.map((time) => moment(time, "HH:mm"));
  let enterTime, leaveTime;
  switch (baseTime.length) {
    case 1:
      enterTime = baseTime[0];
      leaveTime = null;

      break;
    case 3:
      enterTime = baseTime[0];
      leaveTime = baseTime[2];
      break;
    case 4:
      enterTime = baseTime[0];
      leaveTime = baseTime[3];
      break;

    default:
      enterTime = baseTime[0];
      leaveTime = baseTime[baseTime.length - 1];
      break;
  }

  return {
    [colTimeIn]: enterTime,
    [colTimeOut]: leaveTime,
    [colRecordsCount]: time?.length === 0 ? "0" : baseTime.length,
  };
};

export default getTimeInTimeOut;
