import React, { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ProtectedRoute from "./routes/ProtectedRoute";
import AuthProvider from "./context/AuthProvider";
import Root from "./routes/Root";
import CreateEmployee from "./routes/employees/create";
import CreateDaysOff from "./routes/daysoff/create";
import DataUpload from "./routes/data/upload";

const Home = React.lazy(() => import("./routes/home"));
const Departments = React.lazy(() => import("./routes/departments"));
const CreateDepartment = React.lazy(() =>
  import("./routes/departments/create")
);

const Data = React.lazy(() => import("./routes/data"));
const Employees = React.lazy(() => import("./routes/employees"));
const DaysOff = React.lazy(() => import("./routes/daysoff"));

const Login = React.lazy(() => import("./routes/login"));

function App() {
  const location = useLocation();
  const state = location.state;
  return (
    <AuthProvider>
      <Routes location={state?.backgroundLocation || location}>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Root />
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/data" element={<Data />} />
          <Route path="/data/upload" element={<DataUpload />} />
          <Route path="/departments" element={<Departments />} />
          <Route path="/employees" element={<Employees />} />
          <Route path="/daysoff" element={<DaysOff />} />
        </Route>

        <Route
          path="/login"
          element={
            <Suspense fallback={<div>loader</div>}>
              <Login />
            </Suspense>
          }
        />
      </Routes>
      {state?.backgroundLocation && (
        <Routes>
          <Route
            path="/departments/create"
            element={
              <ProtectedRoute>
                <CreateDepartment />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employees/create"
            element={
              <ProtectedRoute>
                <CreateEmployee />
              </ProtectedRoute>
            }
          />
          <Route
            path="/daysoff/create"
            element={
              <ProtectedRoute>
                <CreateDaysOff />
              </ProtectedRoute>
            }
          />
        </Routes>
      )}
    </AuthProvider>
  );
}

export default App;
