import * as React from "react";
import Modal from "../../components/Modal";
import { useDispatch } from "react-redux";

import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createDepartment } from "../../store/departments";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import moment from "moment";
import SearchEmployee from "../../components/SearchEmployee";
import SearchDepartment from "../../components/SearchDepartment";
import { createDayoff } from "../../store/daysoff";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
const initData = {
  days: 1,
  type: "Employee",
  name: "",
  userId: null,
  department: null,
  all: false,
  description: null,
  fromDate: moment().format("YYYY-MM-DD"),
};
export default function CreateDaysOff() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [days, setDays] = React.useState(1);
  const [type, setType] = React.useState("Employee");
  const [name, setName] = React.useState("");
  const [userId, setUserId] = React.useState(null);
  const [department, setDepartment] = React.useState(null);
  const [all, setAll] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [fromDate, setFromDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [data, setData] = React.useState([]);
  const onCreate = () => {
    const toDate = moment(fromDate)
      .add(days - 1, "day")
      .format("YYYY-MM-DD");
    console.log({
      name,
      userId,
      fromDate,
      toDate,
      days,
      department,
      all,
      description,
    });

    if (type === "Employee" && data.length === 0) {
      return alert("Please choose an employee");
    }
    if (type === "Department" && department === null) {
      return alert("Please choose a department");
    }
    setLoading(true);

    const _data = [];
    if (type === "Employee") {
      data.forEach((item) => {
        _data.push({
          name: item.name,
          userId: item.userId,
          fromDate,
          toDate,
          days,
          department,
          all,
          description,
        });
      });
    } else {
      _data.push({
        name,
        userId,
        fromDate,
        toDate,
        days,
        department,
        all,
        description,
      });
    }
    dispatch(
      createDayoff(_data, (e) => {
        if (e.error) {
          setLoading(false);
        }
        if (e.success) {
          toast.success("Data has been Successfully created");
          navigate(-1);
        }
      })
    );
  };

  return (
    <Modal
      title="New Days Off"
      buttons={[
        { title: "Cancel" },
        { title: "Create", loading, onClick: onCreate },
      ]}
    >
      <Stack direction="row" alignItems="center" spacing={2} mb={2}>
        <Typography>From Date</Typography>
        <TextField
          margin="dense"
          value={fromDate}
          type="date"
          fullWidth
          onChange={(e) => setFromDate(e.target.value)}
        />
        <Typography>Days Count</Typography>
        <TextField
          margin="dense"
          value={days}
          onChange={(e) => setDays(e.target.value)}
          type="number"
          fullWidth
        />
      </Stack>

      <FormControl margin="dense">
        <FormLabel id="demo-row-radio-buttons-group-label">Type</FormLabel>
        <RadioGroup
          row
          name="row-radio-buttons-group"
          value={type}
          onChange={(e) => {
            const { value } = e.target;
            setType(value);

            if (value === "All") {
              setName("جميع المنتسبين");
              setUserId(null);
              setData([]);
              setAll(true);
              setDepartment(null);
            }
            if (value === "Employee" || value === "Department") {
              setName("");
              setUserId(null);
              setData([]);
              setAll(false);
              setDepartment(null);
            }
          }}
        >
          {["Employee", "Department", "All"].map((item) => (
            <FormControlLabel
              key={item}
              value={item}
              control={<Radio />}
              label={item}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <table className="table">
        <tbody>
          {data.map((item) => (
            <tr>
              <td>{item.name}</td>
              <td width={20}>
                <IconButton
                  onClick={() => {
                    setData((prev) =>
                      prev.filter((e) => e.userId !== item.userId)
                    );
                  }}
                >
                  <ClearOutlinedIcon />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Box>
        {type === "Employee" && (
          <SearchEmployee
            onChange={(value) => {
              if (!value || data.find((item) => item.userId === value.userId)) {
                return;
              }
              setData((prev) => {
                let newData = [
                  ...prev,
                  { name: value?.name || "", userId: value?.userId || "" },
                ];
                return newData;
              });
              setName("");
              setAll(false);
              setDepartment(null);
            }}
          />
        )}
        {type === "Department" && (
          <SearchDepartment
            onChange={(value) => {
              setName(value?.name || "");
              setUserId(null);
              setAll(false);
              setDepartment(value._id);
            }}
          />
        )}

        <TextField
          placeholder="description"
          margin="dense"
          value={description}
          multiline
          rows={2}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
        />
      </Box>
    </Modal>
  );
}
