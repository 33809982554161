import React, { useMemo, useCallback, useState } from "react";
import { Box, Container, Typography } from "@mui/material";

import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import { AttachFile } from "@mui/icons-material";
import csvToJson from "../utils/csvToJson";
import { colId, colName, colTime } from "../utils/constants";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 5,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#ffff",
  outline: "none",
  transition: "all .24s ease-in-out",
  height: "100%",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#b3d334",
  backgroundColor: "#e1edae",
};

const rejectStyle = {
  borderColor: "#ff1744",
  backgroundColor: "#ff8a80",
};

function DataReader({ onRead }) {
  const [loading, setLoading] = useState(false);
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles[0]) {
        setLoading(true);

        const reader = new FileReader();
        reader.onabort = () => {
          setLoading(false);
        };
        reader.onerror = () => {
          setLoading(false);
        };
        reader.onloadend = () => {
          setLoading(false);
        };
        reader.onload = (evt) => {
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });

          onRead(csvToJson(data));
          setLoading(false);
        };
        reader.readAsBinaryString(acceptedFiles[0]);
      }
    },
    [onRead]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    onDrop,
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <>
      {!loading ? (
        <Box {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <AttachFile fontSize="large" />
          <Typography>اسحب ملف الاكسل الى هنا</Typography>
          <Typography color="GrayText" variant="caption">
            او انقر هنا لاختيار الملف
          </Typography>
          <table className="table">
            <thead>
              <tr>
                <td colSpan={3}>يجب ان يحتوي الملف على الحقول التالية</td>
              </tr>
            </thead>
            <tr>
              <th>{colId}</th>
              <th>{colName}</th>
              <th>{colTime}</th>
            </tr>
          </table>
        </Box>
      ) : (
        <Box style={{ ...baseStyle }}>
          <Typography>جاري قراءة الملف يرجى الانتظار</Typography>
        </Box>
      )}
    </>
  );
}

export default DataReader;
