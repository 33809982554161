import React from "react";

import Box from "@mui/material/Box";

import { Outlet } from "react-router-dom";
import ToolsBar from "../components/toolsbar";

function App() {
  return (
    <Box>
      <div className="noprint">
        <ToolsBar />
      </div>

      <Outlet />
    </Box>
  );
}

export default App;
