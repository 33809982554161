import { createSlice } from "@reduxjs/toolkit";

const localUsers = JSON.parse(localStorage.getItem("users"));
const slice = createSlice({
  name: "users",
  initialState: {
    loading: { login: false },
    currentUser: localUsers?.currentUser,
    users: localUsers?.users || [],
    list: [],
    fetched: false,
    error: {},
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = { ...state.loading, ...action.payload };
      state.error = {};
    },
    loginSuccess: (state, action) => {
      state.loading.login = false;
      state.currentUser = action.payload;
      state.users = [action.payload, ...state.users];
      localStorage.setItem(
        "users",
        JSON.stringify({ users: state.users, currentUser: state.currentUser })
      );
    },
    loginError: (state, action) => {
      state.loading.login = false;
      state.error.login = action.payload.error;
    },
    loadUsers: (state, action) => {
      const { currentUser, users } = action.payload;
      state.currentUser = currentUser;
      state.users = users;
    },
    logoutSuccess: (state, action) => {
      state.currentUser = null;
      localStorage.clear();
    },

    getSuccess: (state, action) => {
      const { data, total } = action.payload;
      state.total = total;
      state.list = data;
      state.loading.get = false;
      state.fetched = true;
    },
    getError: (state, action) => {
      state.loading.get = false;
      state.error.get = action.payload.error;
    },
    createSuccess: (state, action) => {
      state.loading = false;
      state.list.unshift(action.payload);
      state.total += 1;
    },
    createError: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});

export default slice.reducer;

export const { setLoading, loadUsers, loginSuccess, logoutSuccess } =
  slice.actions;

export const loginUser = (data) => (dispatch) => {
  dispatch(setLoading({ login: true }));
  dispatch({
    type: "apiCall",
    payload: {
      url: "/authentication",
      data,
      method: "post",
      onSuccess: "users/loginSuccess",
      onError: "users/loginError",
    },
  });
};
