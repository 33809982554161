import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme({
  direction: "rtl",
  palette: {
    primary: {
      light: "#3c474c",
      main: "#263238",
      dark: "#222d32",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#e9621a",
      main: "#e65100",
      dark: "#cf4900",
      contrastText: "#000000",
    },
    background: {
      default: "#FFFFFF",
      paper: "#EEEEEE",
    },
    divider: "#EEEEEED1",
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableElevation: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
  },
});
export default defaultTheme;
